<template>
  <div>
    <div class="center-text" style="padding: 10px">
      <h1 class="left-text bold small-top half-bottom headerText" style="padding-left:25px;">
        <b>{{ lang[this.appLang].my_connections }} </b> ({{connections.length}})
      </h1>


      <div class="search-field half-top">
        <i class="fa" style="padding-top: 2px">
          <img
            src="https://cdn.tapni.co/icons/loupe.png"
            class="fa responsive-image"
            style="width: 50%"
          />
        </i>
        <input
          v-model="search"
          type="text"
          name="search"
          :placeholder="lang[this.appLang].search + '...'"
          class="search-input"
        />
        <b @click="openFilterModal" class="pointer">
          <img
            :src="
              filterActive
                ? 'https://cdn.tapni.co/icons/sort-active.png'
                : 'https://cdn.tapni.co/icons/sort.png'
            "
            class="fa responsive-image filterButton"
            style="width: 50%"
          />
        </b>
      </div>

      <h5 class="center-text regularbold">
        <span v-if="connections && connections.length > 0"></span>
        <span
            v-else-if="search === '' && !contentLoader"
            v-html="lang[this.appLang].connections_p2"
        ></span>
      </h5>

      <div v-if="contentLoader" class="action-block-top full-top">
        <div class="default-text"></div>
      </div>
      <div
        v-if="connections && connections.length > 0 && !contentLoader"
        class="bg-white max-bottom padding-left"
        style="overflow-y: scroll"
      >
        <Connection
          v-for="(connection, i) in connections"
          :key="connection.id"
          :data="connection"
          :showBorder="i + 1 !== connections.length"
          ref="connectionsRef"
        />
      </div>
      <h5
        v-else-if="search !== '' && !contentLoader"
        class="center-text regularbold full-top half-bottom"
      >
        <span>{{ lang[this.appLang].connections_p3 }}<br /></span>
      </h5>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Connection from "../components/Connection";
import { mapActions } from "vuex";
import { EventBus } from "../store/event-bus";
import VueContext from "vue-context";

export default {
  name: "Connections",
  components: {
    Connection,
    VueContext,
  },
  data() {
    return {
      contentLoader: true,
      search: "",
      data: [],
      selectedConnectionData: {},
      filterActive: false,
    };
  },
  computed: {
    connections() {
      if (this.search === "") {
        return this.data;
      }

      let filtered = [];

      this.data.forEach((connection) => {
        if (this.matchSearch(connection)) {
          filtered.push(connection);
        }
      });

      return filtered;
    },
  },
  methods: {
    ...mapActions(["getConnections", "eventLog", "getUser"]),

    getDefaultFilter() {
      const { start, end } = this.createRangeDate();

      const defaultFilter = {
        dateFrom: start,
        dateTo: end,
        type: "all",
        markers: [],
        sortBy: "createdAt",
        sortDirection: "desc",
      };

      return defaultFilter;
    },

    createRangeDate() {
      const start = this.$moment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      const end = this.$moment().format("YYYY-MM-DD");

      return {
        start,
        end,
      };
    },

    openFilterModal() {
      EventBus.$emit("toggleFilterModal");
    },

    viewProfile() {
      const connection = this.getConnection();

      connection.viewProfile();
    },

    editConnection() {
      const connection = this.getConnection();

      connection.editConnection();
    },

    getConnection() {
      const connectionRef = this.$refs.connectionsRef.find(
        (connection) => connection.data.id === this.selectedConnectionData.id
      );

      return connectionRef;
    },

    openMenu({ event, data }, connectionsRef) {
      this.$refs.menu.open(event);
      this.selectedConnectionData = data;
    },

    matchSearch(connection) {
      if (connection.status === "connected") {
        if (
          connection.receiver &&
          connection.receiver.id !== this.storage.UserId &&
          (connection.receiver.name
            ?.toLowerCase()
            .includes(this.search?.toLowerCase()) ||
            connection.receiver.username
              ?.toLowerCase()
              .includes(this.search?.toLowerCase()))
        )
          return true;

        if (
          connection.requester &&
          connection.requester.id !== this.storage.UserId &&
          (connection.requester.name
            ?.toLowerCase()
            .includes(this.search?.toLowerCase()) ||
            connection.requester.username
              ?.toLowerCase()
              .includes(this.search?.toLowerCase()))
        )
          return true;
      }

      if (
        connection.payload &&
        connection.payload.name &&
        connection.payload.name
          ?.toLowerCase()
          .includes(this.search?.toLowerCase())
      )
        return true;
      if (
        connection.payload &&
        connection.payload.email &&
        connection.payload.email
          ?.toLowerCase()
          .includes(this.search?.toLowerCase())
      )
        return true;
      if (
        connection.payload &&
        connection.payload.phone &&
        connection.payload.phone
          ?.toLowerCase()
          .includes(this.search?.toLowerCase())
      )
        return true;
      return false;
    },

    async init(params = this.getDefaultFilter()) {
      this.filterActive =
        JSON.stringify(this.getDefaultFilter()) !== JSON.stringify(params);

      if (this.user && this.user.username !== this.storage.username) {
        this.$store.commit("setLoading", true);
        await this.getUser({ username: this.storage.username });
      }

      const response = await this.getConnections(params);
      if (response.data.success) {
        this.data = response.data.connections;

        this.eventLog({
          type: "connections_page",
        });
      }

      this.$store.commit("setInitialize", true);
      this.$store.commit("setLoading", false);
      this.contentLoader = false;
    },
  },
  mounted() {
    if (this.route) this.init();
    if(this.$route.query && this.$route.query.search) this.search = this.$route.query.search;
    EventBus.$on("refreshConnections", this.init);
  },
  watch: {
    "route.name"(nv, ov) {
      if (!ov && nv) this.init();
    },
  },
};
</script>

<style scoped>
v-context {
  border-radius: 12px;
}

.v-context div {
  cursor: pointer;
}

.v-context div:hover {
  background: rgb(222, 222, 222);
}

.search-input {
  background-color: #f4f4f4;
  border-radius: 50px !important;
  border-bottom: none;
  padding-left: 35px;
  padding-bottom: 4px;
}

.search-field {
  display: flex;
  align-items: center;
}

.search-field input {
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
}

.search-field i {
  left: 15px;
}

.filterButton {
  width: 30px !important;
}

.search-field b {
  margin-right: 20px;
  display: flex;
}
</style>
